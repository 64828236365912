import React, { useEffect, useState } from "react";
import "./cont-hero.css";
import Swal from "sweetalert2";
import { MdLocationPin } from "react-icons/md";
import { db } from "../firebaseconfig";
import { collection, addDoc } from "firebase/firestore";
import Aos from "aos";
Aos.init();
const ContactHero = () => {
  const [name, setName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [loader, setLoader] = useState(false);

  const removeData = () => {
    setName("");
    setLastName("");
    setEmail("");
    setMessage("");
  };

  const handleSubmit = async () => {
    if (name === "" || lastName === "" || email === "" || message === "") {
      Swal.fire({
        icon: "error",
        title: "Fill Required Fields",
        text: "Fill the details before sending message",
      });
    } else {
      let collectionRef = collection(db, "feedback");
      setLoader(true);
      await addDoc(collectionRef, {
        firstName: name,
        lastName: lastName,
        email: email,
        feedback: message,
        time: new Date().getTime(),
      });
      setLoader(false);
      Swal.fire({
        icon: "success",
        title: "Message Sent",
        text: "Thanks for your messaging",
      });
      removeData();
    }
  };

  return (
    <>
      <div className="max-width-wrap">
        <div className="max-width">
          <div>
            <div className="head-wrapp">
              <div className="cont-head-main">
                <br />
                <div className="head-data">
                  <h2 className="heading-section cont-h1">GET IN TOUCH</h2>
                  <p className="cont-p">
                    Reach out to us for a smarter and more efficient delivery
                    solution!
                  </p>
                  <a href="#form-trrr">
                    <button>SEND MESSAGE</button>
                  </a>
                </div>
              </div>
            </div>

            <div>
              <section className="ftco-section">
                <div className="container">
                  <div className="row "></div>
                  <div className="row justify-content-center">
                    <div className="col-md-12">
                      <div className="wrapper con-main-wrapper">
                        <div
                          data-aos="fade-top"
                          data-aos-easing="linear"
                          data-aos-duration="500"
                          className="row mb-5"
                        >
                          <div className="col-md-3">
                            <div className="dbox w-100 text-center">
                              <div className="icon d-flex align-items-center justify-content-center">
                                <span className="fa fa-map-marker" />
                              </div>
                              <div className="text">
                                <p>
                                  <span>Address:</span> <br />
                                  <a href="#"> &nbsp; Abu Dhabi , UAE </a>
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-3">
                            <div className="dbox w-100 text-center">
                              <div className="icon d-flex align-items-center justify-content-center">
                                <span className="fa fa-phone" />
                              </div>
                              <div className="text">
                                <p>
                                  <span>Phone:</span>
                                  <br />
                                  <a href="tel://971556751337">
                                    + 971556 7513 37
                                  </a>
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-3">
                            <div className="dbox w-100 text-center">
                              <div className="icon d-flex align-items-center justify-content-center">
                                <span className="fa fa-paper-plane" />
                              </div>
                              <div className="text">
                                <p>
                                  <span>Email:</span>
                                  <br />
                                  <a href="mailto:info@yoursite.com">
                                    haroon.william77@gmail.com
                                  </a>
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-3">
                            <div className="dbox w-100 text-center">
                              <div className="icon d-flex align-items-center justify-content-center">
                                <span className="fa fa-globe" />
                              </div>
                              <div className="text">
                                <p>
                                  <span>Website</span>
                                  <br />
                                  <a href="#">Breakaway.ae</a>
                                </p>
                              </div>
                            </div>
                          </div>
                          <hr
                            id="form-trrr"
                            className="hr-cotact"
                            style={{ color: "#0d77ee" }}
                          />
                        </div>
                        <div className="row no-gutters">
                          <div className="col-md-7">
                            <div className="contact-wrap w-100 p-md-5 p-4">
                              <h3 className="mb-4 bolda">Send Message</h3>
                              <div id="form-message-warning" className="mb-4" />
                              <div
                                // method="POST"
                                id="contactForm"
                                name="contactForm"
                                className="contactForm"
                              >
                                <div className="row">
                                  <div className="col-md-6">
                                    <div className="form-group">
                                      <label className="label" htmlFor="name">
                                        First Name
                                      </label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        name="name"
                                        id="name"
                                        value={name}
                                        onChange={(e) => {
                                          setName(e.target.value);
                                        }}
                                        placeholder="First Name"
                                      />
                                    </div>
                                  </div>
                                  <div className="col-md-6">
                                    <div className="form-group">
                                      <label className="label" htmlFor="email">
                                        Last Name
                                      </label>
                                      <input
                                        type="email"
                                        className="form-control"
                                        name="lastname"
                                        id="email"
                                        placeholder="Last Name"
                                        value={lastName}
                                        onChange={(e) => {
                                          setLastName(e.target.value);
                                        }}
                                      />
                                    </div>
                                  </div>
                                  <div className="col-md-12">
                                    <div className="form-group">
                                      <label
                                        className="label"
                                        htmlFor="subject"
                                      >
                                        Email
                                      </label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        name="emal"
                                        id="subject"
                                        placeholder="Email"
                                        value={email}
                                        onChange={(e) => {
                                          setEmail(e.target.value);
                                        }}
                                      />
                                    </div>
                                  </div>
                                  <div className="col-md-12">
                                    <div className="form-group">
                                      <label className="label" htmlFor="#">
                                        Message
                                      </label>
                                      <textarea
                                        name="message"
                                        className="form-control"
                                        id="message"
                                        cols={30}
                                        rows={4}
                                        placeholder="Message"
                                        defaultValue={""}
                                        value={message}
                                        onChange={(e) => {
                                          setMessage(e.target.value);
                                        }}
                                      />
                                    </div>
                                  </div>
                                  <div className="col-md-12">
                                    <div className="form-group">
                                      <button
                                        className="cont-btn"
                                        onClick={handleSubmit}
                                      >
                                        {loader ? (
                                          <div className="spinner-border"></div>
                                        ) : (
                                          "SEND MESSAGE"
                                        )}
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-5 d-flex align-items-stretch">
                            <div
                              className="info-wrap w-100 p-5 img"
                              style={{
                                backgroundImage:
                                  'url("https://images.pexels.com/photos/1181542/pexels-photo-1181542.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1")',
                              }}
                            ></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>

            {/* contact details/map start */}

            <section id="summer-collection">
              <div className="container container-map">
                <div className="sc-content contact-sec-get-touch"></div>
                <div className="sc-media">
                  <div className="sc-media-bg">
                    <div className="map-head">
                      <h2 className="map-h1">
                        <MdLocationPin
                          style={{
                            color: "#0d77ee",
                            marginBottom: "5px",
                            marginTop: "-4px",
                          }}
                        />{" "}
                        Find Us
                      </h2>
                      <p className="map-p">
                        To visit our head office, simply refer to the map
                        provided. Our office is located in a convenient and
                        easily accessible area, ensuring that you have no
                        trouble finding your way.
                      </p>
                      <hr />
                    </div>
                    <iframe
                      className="iframe-map"
                      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d40301.52369426011!2d54.39098282242161!3d24.55098097399437!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5e6724630067f1%3A0x42191ffb02753df9!2sLouvre%20Abu%20Dhabi!5e0!3m2!1sen!2s!4v1673172437935!5m2!1sen!2s"
                      width={"100%"}
                      height={400}
                      style={{ border: 0, margin: "0px" }}
                      allowFullScreen
                      loading="lazy"
                      referrerPolicy="no-referrer-when-downgrade"
                    />
                    <br />
                    <br />
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactHero;
