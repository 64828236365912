import React from "react";
import RegisterationForm from "./Registeration";
export default function Register() {
  return (
    <>
    <div className="max-width-wrap">
<div className="max-wtidth">

      <RegisterationForm />
</div>
    </div>
    </>
  );
}
