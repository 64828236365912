import { Carousel,Timeline } from "antd";
import "./testimonial.css";
import slide from "./media/review.png";
import review1 from "./media/review1.png";
import slide2 from "./media/review2.png";
import review3 from "./media/review3.png";

const Testimonial = () => (
    <div className="testimonial-wrap">
  <Carousel autoplay dots={false}>
    <div className="sliderDiv">
      <img className="about-slide-img" src={review1} alt="" />
    </div>

    <div className="sliderDiv">
      <img className="about-slide-img" src={slide} alt="" />
    </div>
    <div className="sliderDiv">
      <img className="about-slide-img" src={slide2} alt="" />
    </div>
    <div className="sliderDiv">
      <img className="about-slide-img" src={review3} alt="" />
    </div>
  </Carousel>
    </div>
);

const Procedure = () => (
  
  
  <Timeline>
    <Timeline.Item>Recive your order and <b>review</b>. </Timeline.Item>
    <Timeline.Item><b>Confirmation by calling  </b>to the cutomer. </Timeline.Item>
    <Timeline.Item>Prepare rider for your order and send it to you</Timeline.Item>
    <Timeline.Item>Pick your Item from your selected location </Timeline.Item>
    <Timeline.Item> <b> Quickly delivered </b> it, Where You want</Timeline.Item>
  </Timeline>
);
export  {Procedure ,Testimonial};
