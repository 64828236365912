import React from "react";
import { useEffect } from "react";
import '../../heroSection/hero.css'

import { Link } from "react-router-dom";
import "../NicePng/Home.css";
import "../NicePng/Blog-Template.css";
import "../NicePng/nicepage.css";
import "../NicePng/Post-Template.css";
import { getAuth, onAuthStateChanged } from "firebase/auth";

import Facility from "../../COMPANY Components/comp facility/facility";
import Difference from "../../COMPANY Components/difference comp&individual/compvsindividual";
export default function Compreg() {
  useEffect(() => {
    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {
    });
  });

  return (
    <div className="max-width-wrap">
      <div className="max-width">
        <div className="compcomp-reg-main-wrap">
          <div className="compultra">
            <div className="comphero-wrap comp-hero-main">
              <div className="comphero-main">
                <div className="compfin-main w3-animate-bottom">
                  <h1 className="comphero-h1">
                <span className="comphero-quote">
                      Sign Up Now &{" "}
                    </span>
                    <br />
                    Experience the Best in Delivery Services
                  </h1>
                  <div className="compheroHr"></div>
                  <p className="comphero-p">
                    By registering with Breakaway, you can grow your business
                    and take your customer satisfaction to the next level. Our
                    wide range of delivery options, including same-day and
                    next-day delivery, will allow you to meet your customers'
                    needs and exceed their expectations.
                  </p>
                  <div className="comphero-buttons-wrap">
                    <Link to={"/ordersCompany"}>
                      <button className="compcr-btn">COMPANY ORDER</button>
                    </Link>
                    <Link to={"/contact"}>
                      <button className="comptr-btn">CONTACT US</button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/*  */}

          {/*  */}

          <Facility />

          <hr />

          <div className="main-comp-body">
            <section
              className="u-align-center u-clearfix u-section-7"
              id="carousel_d094"
            >
              <div className="u-clearfix u-sheet u-valign-middle-lg u-valign-middle-md u-valign-middle-sm u-valign-middle-xl u-sheet-1">
                <div className="u-expanded-width u-tab-links-align-justify u-tabs u-tabs-1">
                  <ul
                    className="u-border-1 u-border-grey-25 u-tab-list u-unstyled"
                    role="tablist"
                  >
                    <li className="u-tab-item" role="presentation">
                      <a
                        className="active u-active-white u-border-active-black u-border-hover-grey-15 u-button-style u-custom-font u-font-ubuntu u-palette-5-light-2 u-tab-link u-text-active-black u-text-body-color u-text-hover-black u-tab-link-1"
                        id="link-tab-0da5"
                        href="#tab-0da5"
                        role="tab"
                        style={{ textAlign: "left", padding: "30px" }}
                        aria-controls="tab-0da5"
                        aria-selected="true"
                      >
                        How to make an order with breakaway
                      </a>
                    </li>
                  </ul>
                  <div className="u-tab-content">
                    <div
                      className="u-container-style u-tab-active u-tab-pane u-white u-tab-pane-1"
                      id="tab-0da5"
                      role="tabpanel"
                      aria-labelledby="link-tab-0da5"
                    >
                      <div className="u-container-layout u-valign-top u-container-layout-1">
                        <img
                          alt=""
                          className="u-expanded-width-xs u-image u-image-default u-image-1"
                          data-image-width={864}
                          data-image-height={1080}
                          src="https://nahidenterprises.com/wp-content/uploads/2021/02/Courier-Cargo-Service.png"
                        />
                        <h4 className="u-text u-text-1">Description</h4>

                        <div className="padding-mobi">
                        <p className="u-text u-text-grey-40 u-text-2">
                          Ordering a delivery with our breakaway delivery
                          is simple and straightforward, even for registered
                          users. Here are the steps you need to follow:
                        </p>
                        <ul className="u-text u-text-3">
                          <li>
                            <div className="class u-list-icon" />
                            Log in to your account by using app or website{" "}
                          </li>
                          <li>
                            <div style={{height:'10px'}}></div>
                            Enter delivery details on form
                          </li>
                          <li>
                            <div style={{height:'10px'}}></div>
                            Review and confirm order by pressing button
                          </li>
                          <li>
                            <div style={{height:'10px'}}></div>
                            Our team will verify your order by calling you 
                          </li>
                          <li>
                            <div style={{height:'10px'}}></div>
                            Confirm your order 
                          </li>
                          <li>
                            <div style={{height:'10px'}}></div>
                            Contact Helpline for any kind of queries.
                          </li>
                        </ul>
                      </div>
                    </div>
                    </div>
                    <div
                      className="u-container-style u-tab-pane u-white u-tab-pane-2"
                      id="tab-2917"
                      role="tabpanel"
                      aria-labelledby="link-tab-2917"
                    >
                      <div className="u-container-layout u-valign-top u-container-layout-2">
                        <h4 className="u-text u-text-default u-text-4">
                          Be The First To Review This Product!
                        </h4>
                        <p className="u-text u-text-default u-text-5">
                          Sample text. Click to select the text box. Click again
                          or double click to start editing the text.
                        </p>
                        <a
                          href="#"
                          className="u-black u-btn u-button-style u-btn-1"
                        >
                          write product review
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {/*  */}

            <div className="s2nd-hero-comp">
              <h2>Eligibility Criteria</h2>
              <h4>Requirements for registration</h4>

              <div className="wrapper con-main-wrapper">
                <br />
                <br />
                <div
                  data-aos="fade-top"
                  data-aos-easing="linear"
                  data-aos-duration="500"
                  className="row mb-5"
                >
                  <div className="col-md-3">
                    <div className="dbox w-100 text-center">
                      <div className="icon d-flex align-items-center justify-content-center">
                        <span className="fa fa-registered" />
                      </div>
                      <div className="text">
                        <p>
                          <span>Business should be registered.</span>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="dbox w-100 text-center">
                      <div className="icon d-flex align-items-center justify-content-center">
                        <span className="fa fa-building" />
                      </div>
                      <div className="text">
                        <p>
                          <span>Head office required for verification. </span>{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="dbox w-100 text-center">
                      <div className="icon d-flex align-items-center justify-content-center">
                        <span className="fa fa-info" />
                      </div>
                      <div className="text">
                        <p>
                          <span>Business license required.</span>{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="dbox w-100 text-center">
                      <div className="icon d-flex align-items-center justify-content-center">
                        <span className="fa fa-credit-card-alt" />
                      </div>
                      <div className="text">
                        <p>
                          <span>Corporate babk account </span>
                        </p>
                      </div>
                    </div>
                  </div>
                  <hr className="hr-cotact" style={{ color: "#0d77ee" }} />
                </div>
              </div>
              <p style={{ textAlign: "justify" }}>
                To become a registered user of our breakaway delivery,
                you must meet certain eligibility criteria. Here are the
                requirements you must fulfill in order to register: <br />
                <ul>
                  <li style={{ listStyle: "outside" }}>
                    {" "}
                    Business or personal usage: Our services are available for
                    both businesses and individuals, but the specific
                    requirements for each may vary.
                  </li>
                  <li style={{ listStyle: "outside" }}>
                    Contact information: You must provide valid contact
                    information, including a full name, email address, and phone
                    number.
                  </li>
                  <li style={{ listStyle: "outside" }}>
                    Delivery needs: We will ask about the types of deliveries
                    you need, the frequency of deliveries, and any special
                    requirements you may have.
                  </li>
                  <li style={{ listStyle: "outside" }}>
                    Payment method: You must provide a valid payment method for
                    the services you receive.
                  </li>
                </ul>
                {/* 4) 
                <br /> */}
                By meeting these eligibility criteria, you can become a
                registered user of our breakaway delivery and enjoy the
                many benefits we have to offer. If you have any questions about
                the registration process, our support teams are always available
                to assist.
              </p>
              <Link to={"/signup"}>
                <button className="brk-btn"> Apply For Registration</button>
              </Link>
            </div>

            {/*  */}
            <section
              className="u-align-center u-clearfix u-section-8"
              id="carousel_16c8"
            ></section>
            <Difference />
          </div>
        </div>
      </div>
    </div>
  );
}
