import React from "react";
import "./facility.css";
import { MdOutlineNavigateNext } from "react-icons/md";
import { TbTruckDelivery } from "react-icons/tb";
import { MdQuickreply } from "react-icons/md";
import { BsCashCoin } from "react-icons/bs";
import { VscWorkspaceTrusted } from "react-icons/vsc";
import missionImage from "../../medias/mission.png"
import agreementImage from "../../medias/agreement.png"
import boostBusinessImage from "../../medias/boost_business-removebg-preview.png"
import supportImage from "../../medias/support.png"
import Aos from "aos";
import { Link } from "react-router-dom";
Aos.init();
export default function Facility() {
  return (
    <>
      <div
        data-aos="fade-up"
        data-aos-anchor-placement="top-bottom"
        className="freights-wrap"
      >
        <div className="freights align-center">
          <div className="Frgbox2 frg-box-comp">
            <div className="img-wrap-comp">
              <img
                src={boostBusinessImage}
                className="frg-img  comp-img"
                
              />
            </div>
            <h5 className="clrBrk pb5 b">Boost Your Business</h5>
            <p className="ligt-p pb5 facil_P">
              Partnering with Breakaway for <br /> your delivery needs can{" "}
              <br /> improve your business's efficiency <br /> and reliability,
              ensuring timely and secure delivery of your products to customers.
            </p>
            <Link to="/signup" className="down-btn-comp">
              <a>Connect Business</a>
            </Link>
            <br />
            {/* <MdOutlineNavigateNext className="frg-icon" /> */}
          </div>
          <div className="Frgbox3 frg-box-comp">
            <div className="img-wrap-comp">
              <img
                src={missionImage}
                className="frg-img comp-img"
                alt=""
              />
            </div>
            <h5 className="clrBrk pb5 b">Our Mission and Values</h5>
            <p className="ligt-p pb5 facil_P">
              Breakaway boasts expert senior management and a
              commitment to exceptional service. With decades of experience and
              a focus on meeting client needs, Explore our company's mission and values here  
              <br />
            </p>
            <Link to="/about" className="down-btn-comp">
              <a>Learn More</a>
            </Link>
            <br />
          </div>
          <div className="Frgbox3 frg-box-comp">
            <div className="img-wrap-comp">
              <img
                src={agreementImage}
                className="frg-img comp-img"
                alt=""
              />
            </div>
            <h5 className="clrBrk pb5 b">Long Term Aggreements</h5>
            <p className="ligt-p pb5 facil_P ">
              Make a long-term agreement by registering with Breakaway for a reliable and
              cost-effective delivery solution that will meet your business's
              evolving needs and help you save on shipping costs.
            </p>
            <Link className="down-btn-comp" to="/signup">
              <a>Let's Register</a>
            </Link>

            <br />
          </div>
          <div className="Frgbox3 frg-box-comp">
            <div className="img-wrap-comp">
              <img
                src={supportImage}
                className="frg-img comp-img"
                alt=""
              />
            </div>
            <h5 className="clrBrk pb5 b">24/7 Special Support </h5>
            <p className="ligt-p pb5 facil_P">
              For a hassle-free delivery experience, our dedicated customer
              support team is available 24/7 to promptly assist you with any
              shipment inquiries and issue reporting.
            </p>
            <Link to="/contact" className="down-btn-comp">
              <a>Get Support</a>
            </Link>

            <br />
          </div>
        </div>

        <section className="u-clearfix u-section-5" id="carousel_38a9">
          <div className="u-clearfix u-sheet u-sheet-1">
            <div className="u-clearfix u-expanded-width u-layout-wrap u-layout-wrap-1">
              <div className="u-layout">
                <div className="u-layout-row">
                  <div
                    className="u-container-style u-layout-cell u-size-30 u-layout-cell-1"
                    data-animation-name="customAnimationIn"
                    data-animation-duration={2000}
                  >
                    <div className=" pd-5 u-container-layout u-valign-middle u-container-layout-1">
                      <h2 className="h2-comp-2nd">
                        We Are The Best Agency To Improve Your Deals
                      </h2>
                      {/* <h4 className="u-custom-font u-font-montserrat u-text u-text-palette-5-light-1 u-text-2">
                        We work directly for our clients and put client’s
                        interests first.
                      </h4> */}
                      <p className="p2-comp">
                        At our agency, we pride ourselves in providing the best
                        solutions for improving your delivery deals. With a team
                        of highly skilled and experienced professionals, we have
                        a deep understanding of the delivery industries and the
                        challenges faced by businesses. Our innovative
                        approaches, combined with cutting-edge technology,
                        enable us to streamline processes, increase efficiency,
                        and reduce costs. Additionally, we prioritize open
                        communication and maintain a strong commitment to our
                        clients, ensuring that their needs and expectations are
                        consistently met.
                        <span className="hide-mb">
                          With a proven track record of delivering results, we
                          are confident in our ability to improve your delivery
                          deals and help your business reach new heights of
                          success. Choose us as your trusted partner, and let us
                          show you why we are the best agency to improve your
                          delivery deals.
                        </span>
                      </p>
                    </div>
                  </div>
                  <div className="u-container-style u-layout-cell u-palette-5-light-3 u-size-30 u-layout-cell-2">
                    <div className="u-container-layout u-valign-middle u-container-layout-2">
                      <div className="u-expanded-width u-list u-list-1">
                        <div className="u-repeater u-repeater-1">
                          <div className="u-align-center u-container-style u-list-item u-repeater-item">
                            <div className="u-container-layout u-similar-container u-valign-top u-container-layout-3">
                              <h3
                                className="u-text u-text-palette-5-base u-text-4"
                                data-animation-name="counter"
                                data-animation-event="scroll"
                                data-animation-duration={3000}
                              >
                                <VscWorkspaceTrusted />
                              </h3>
                              <p className="u-text u-text-grey-40 u-text-5">
                                We are a secure and reliable partner for your
                                business, committed to protecting your assets
                                and ensuring confidentiality.
                              </p>
                            </div>
                          </div>
                          <div className="u-align-center u-container-style u-list-item u-repeater-item">
                            <div className="u-container-layout u-similar-container u-valign-top u-container-layout-4">
                              <h3
                                className="u-text u-text-palette-5-base u-text-6"
                                data-animation-name="counter"
                                data-animation-event="scroll"
                                data-animation-duration={3000}
                              >
                                <TbTruckDelivery />
                              </h3>
                              <p className="u-text u-text-grey-40 u-text-7">
                                We offer a wide range of delivery services to
                                meet your business needs, from same-day to
                                international options.
                              </p>
                            </div>
                          </div>
                          <div className="u-align-center u-container-style u-list-item u-repeater-item">
                            <div className="u-container-layout u-similar-container u-valign-top u-container-layout-5">
                              <h3
                                className="u-text u-text-palette-5-base u-text-8"
                                data-animation-name="counter"
                                data-animation-event="scroll"
                                data-animation-duration={3000}
                              >
                                <BsCashCoin />
                              </h3>
                              <p className="u-text u-text-grey-40 u-text-9">
                                We provide convenient cash on delivery services
                                for companies and individuals, ensuring secure
                                payment options for all deliveries.
                              </p>
                            </div>
                          </div>
                          <div className="u-align-center u-container-style u-list-item u-repeater-item">
                            <div className="u-container-layout u-similar-container u-valign-top u-container-layout-6">
                              <h3
                                className="u-text u-text-palette-5-base u-text-10"
                                data-animation-name="counter"
                                data-animation-event="scroll"
                                data-animation-duration={3000}
                              >
                                <MdQuickreply />
                              </h3>
                              <p className="u-text u-text-grey-40 u-text-11">
                                Offering unlimited instant response to your
                                inquiries, providing timely and efficient
                                support for all your needs
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      {/* <div className="facility-wrap">
        <div className="comp-facility">
          <div className="centerer">
            <div className="facility facb">
              <div className="face-img-wrap">
                <img
                  src="https://cdn-icons-png.flaticon.com/512/4766/4766874.png"
                  alt=""
                  className="fac-icon"
                />
              </div>

              <h2 className="comp-facl-h2"> </h2>
              <p className="faclit-p">
                Lorem ipsueniti asperiores iure consequuntur alias cupiditate
                eos electus vom dolor sit amet consectetur adip
              </p>
            </div>
            <div className="facility facw">
              <div className="face-img-wrap">
                <img
                  src="http://cdn.onlinewebfonts.com/svg/img_62163.png"
                  alt=""
                  className="fac-icon"
                />
              </div>
              <p className="faclit-p">
                Lorem ipsueniti asperiores iure consequuntur alias cupiditate
                eos electus vom dolor sit amet consectetur adipisici
              </p>
            </div>
            <div className="facility facb ">
              <div className="face-img-wrap">
                <img
                  src="https://cdn-icons-png.flaticon.com/512/45/45880.png"
                  alt=""
                  className="fac-icon"
                />
              </div>
              <h2 className="comp-facl-h2"></h2>
              <p className="faclit-p">
                Lorem ipsueniti asperiores iure consequuntur alias cupiditate
                eos electus vom dolor sit amet consectetur adipisicing elit.
              </p>
            </div>
            <div className="facility facw">
              <div className="face-img-wrap">
                <img
                  src="https://cdn-icons-png.flaticon.com/512/75/75746.png"
                  alt=""
                  className="fac-icon"
                />
              </div>

              <h2 className="comp-facl-h2"> </h2>
              <p className="faclit-p">
                Lorem ipsueniti asperiores iure consequuntur alias cupiditate
                eos electus vom dolor sit amet consectetur adipis
              </p>
            </div>
          </div>
        </div>
      </div> */}
    </>
  );
}
