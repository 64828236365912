import Header from "../../navbar/navbar";
import React from "react";
import Categories from "../../categories/categories";
import Carausal from "../../carausal/carausal"
import Spacer from "../../spacer-for-header/spacer";
import Footer from "../../Footer/footer"
export default function Gifts() {
  return (
    <>
<div className="max-width-wrap">
<div className="max-width">

      <Header />
      <Spacer/>
      <Carausal/>    
      <Categories />
      <Footer/>
</div>
</div>
    </>
  );
}
