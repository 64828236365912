import React from "react";
import "./options.css";
import { RiGift2Fill } from "react-icons/ri";
import { MdBusinessCenter } from "react-icons/md";
import { RxPerson } from "react-icons/rx";
import Aos from "aos";
Aos.init();
export default function Options() {
  return (
    <>
      <div className="ultra-all-wrap">
        <div className="ultra-all">
          <div className="options-real-wrapp">
            <div className="options-head-wrap">
              <div className="options-head">
                <h1>Our Top Services</h1>
                <p className="options-head-p">
                  Breakaway offers top-notch delivery services for
                  individual, company, and gift orders. Whether you need to send
                  a personal item, a shipment for your business, or a gift to a
                  loved one, we have the solution for you. Our team of
                  experienced professionals will ensure that your order is
                  handled with care and delivered promptly and efficiently.
                  Choose Breakaway for all your delivery needs and
                  experience the ultimate in convenience, reliability, and peace
                  of mind.
                </p>
              </div>
            </div>
            
            <div className="options-wrap">
              <div data-aos="fade-up" className="options-main-wrap">
                <div className="options-main">
                  <div className="option-box lef topinga">
                    <div className="opt-box-head">
                      <RxPerson className="Options-box-icon" size={"45px"} />
                      <h4>Individual</h4>
                    </div>
                    <p>
                      Whether you're sending a personal item or a small package,
                      our individual order service is the perfect solution for
                      your needs.
                    </p>
                    <button className="terara">Let's Try</button>
                  </div>
                  <div className="option-box cen ">
                    <div className="opt-box-head">
                      <MdBusinessCenter
                        className="Options-box-icon"
                        size={"45px"}
                      />
                      <h4>Company</h4>
                    </div>
                    <p>
                      This service is for businesses of all sizes. we make it
                      easy to send shipments to clients, suppliers, or branch
                      offices.
                    </p>
                    <button className="terara">Let's Try</button>
                  </div>
                  <div className="option-box rgt topinga">
                    <div className="opt-box-head">
                      <RiGift2Fill className="Options-box-icon" size={"45px"} />
                      <h4>Wishes</h4>
                    </div>
                    <p>
                      Our experienced professionals will handle your gifts orders with
                      care, ensuring that they are delivered on time and in
                      perfect condition.
                    </p>
                    <button className="terara">Let's Try</button>
                  </div>{" "}
                 <hr />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
