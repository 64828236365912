import React from 'react'
import './spacer.css'
export default function Spacer() {

    return (


        <>
        <div className="spacer">

        </div>

        </>
    )
}
