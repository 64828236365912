import React from "react";
import "./square.css";
import Aos from "aos";
import { Link } from "react-router-dom";
Aos.init();

export default function Square() {
  return (
    <>
      <div className="square-main-wrap">
        <div className="square-main">
          <div data-aos="zoom-in-up" className="square1 sqBox">
            <div className="square-data-1">
              <h1>
                Share gifts with your favorite <br /> person
              </h1>
              <p className="extra-p">
                Send love and joy to your loved ones with Breakaway. Our
                delivery service make's sharing gifts easy and stress-free.
              </p>
              <Link to="/gifts">
                <button className="brk-Btn">Find & Share</button>
              </Link>
            </div>
          </div>
          <div className="square2 sqBox"></div>

          {/* //////////////////////////////////////////////////////////////////////////////// */}

          <div className="square3 sqBox"></div>

          <div data-aos="zoom-in-up" className="square4 sqBox">
            <div className="square-data-2">
              <h3>How we work</h3>
              <p>
                Breakaway makes delivery simple and efficient. Choose from
                the flexible delivery options. <span className="breakaway">
                <Link to='/about'>
                 More.
                </Link>
                </span>
                <span className="extra-p">
                  track your packages in real-time, and enjoy exceptional
                  customer service. Trust us to handle your deliveries with
                  care.
                </span>
              </p>
              {/* <button className="brkLight-Btn">Learn More</button> */}
            </div>
          </div>

          <div data-aos="zoom-in-down" className="square1  sqBox">
            <div className="square-data-3">
              <h1>Bring joy to your loved ones</h1>
              <p className="extra-p">
                lives with a surprise gift from Breakaway delivery. Convenient
                online shopping and fast delivery options make gift-giving easy
                and memorable.
              </p>
              <Link to="/gifts">
                <button className="brk-Btn">Let's Share</button>
              </Link>
            </div>
          </div>
          <div className="square2 sqBox teesra-gifta"></div>
        </div>
      </div>
    </>
  );
}
