import React from "react";
import { Testimonial } from "../clients review/testimonial";
import profileImg from "./media/user.png";
import founderIMG from "./media/founderVector.jpg";
import Footer from "../Footer/footer";
import Header from "../navbar/navbar";
import BlOgo from "./media/blogo.png";
import "./about.css";

import {
  GiDeliveryDrone,
  GiCargoShip,
  GiCash,
  GiRobotGrab,
} from "react-icons/gi";
import { MdNightsStay } from "react-icons/md";
import Spacer from "../spacer-for-header/spacer";
import Aos from "aos";
Aos.init();
export default function About() {
  return (
    <>
      <Header />
      <Spacer />
      <div className="max-width-wrap">
        <div className="max-width">
          <div className="abt-head-wrapp">
            <div className="abt-head-main">
              <br />
              <div className="abt-head-data" id="head">
                <div className="abt-jug-wrap">

                  <h2 className="heading-section cont-h1 blk mb40">ABOUT US</h2>
                  <img src={BlOgo} alt="" className="hero-logoB" />
                </div>
                <p className="cont-p blk abt-p">
                  Breakaway is a dynamic and innovative Delivery company that is
                  dedicated to providing the highest quality services to our
                  customers. With a team of experienced professionals and a
                  state-of-the-art logistics network,
                  <br /> we are committed to
                  delivering your packages quickly, safely, and on time. Whether
                  you're sending a small package or a large shipment, you can
                  trust that Breakaway will handle your items with care and
                  precision.
                  <br />
                  So if you're looking for a reliable and efficient
                  Delivery service, look no further than Breakaway.
                </p>
                <a href="#meet">
                  <button>Meet Our Team</button>
                </a>
              </div>
            </div>
          </div>
          {/* <br /> */}
          <div className="how-we-work-main-wrap">
            <h2>
              <span className="breakaway"> Breakaway </span>Visions
            </h2>
            <hr />
            <div className="how-we-work-main">
              <div data-aos="zoom-in-right" className="hww-left">
                <p>
                  our vision is to be the leading Delivery company, providing
                  unparalleled services to our customers. We strive to exceed
                  expectations by delivering packages with speed, accuracy, and
                  reliability. <br /> Our goal is to create long-lasting
                  relationships with our customers through exceptional customer
                  service and innovative solutions.
                  <br /> We are dedicated to continuously improving our
                  processes and technology to ensure that we are always at the
                  forefront of the Delivery industry.
                  <br /> With our commitment to excellence, Breakaway is your
                  trusted partner for all of your delivery needs. <br />
                </p>
              </div>
              <div data-aos="fade-left" className="hww-right">
                <img
                  src="https://www.freeiconspng.com/thumbs/analytic-icon/analytic-icon-1.png"
                  width={"180px"}
                  style={{ margin: "0px auto" }}
                  alt=""
                />
              </div>
            </div>

            {/* Hero 2 Strats */}

            <div className="hero-2" id="SecHero">
              <div className="hero-2-left">
                <img src={founderIMG} alt="" className="manager-image" />
              </div>
              <div className="hero-2-right">
                <h1>
                  CEO
                  <br /> <span className="name-span"> MR. HAROON </span>
                </h1>
                <p>
                  Breakaway was founded by Mr. Haroon Gill, who brought many
                  years of experience in the Delivery industry to the table.
                  With a passion for innovation and a commitment to excellence,
                  Mr. Haroon Gill set out to create a company that would deliver
                  packages with speed, accuracy, and reliability. Today,
                  Breakaway is a leading Delivery company, and Haroons's vision
                  and leadership continue to guide our team as we strive to
                  exceed customer expectations and set new standards in the
                  industry."
                </p>
                <a href="#visions-main">
                  <button id="visions-main">Founder's Visions</button>
                </a>
              </div>
            </div>
            {/* Hero 2 Ends */}

            <div className="visions-main-wrap">
              <div className="visions-main" >
                <div data-aos="zoom-out-top" className="visions-list-wrap">
                  <div
                    data-aos="fade-down"
                    data-aos-duration="2000"
                    className="visions-list"
                  >
                    <div className="visions-box">
                      <div className="vision-icon">
                        <GiDeliveryDrone className="ion" />
                      </div>
                      <h3>Drone Service</h3>
                      {/* <p>Lorem ipsum dolor sit adipisicing dolor sit amet, .</p> */}
                    </div>
                    <div className="visions-box">
                      <div className="vision-icon">
                        <GiCargoShip className="ion" />
                      </div>
                      <h3>Cargo Service</h3>
                      {/* <p>Lorem ipsum dolor sit adipisicing dolor sit amet, .</p> */}
                    </div>
                    <div className="visions-box">
                      <div className="vision-icon">
                        <MdNightsStay className="ion" />
                      </div>
                      <h3>Overnight Service</h3>
                      {/* <p>Lorem ipsum dolor sit adipisicing dolor sit amet, .</p> */}
                    </div>
                    <div className="visions-box">
                      <div className="vision-icon">
                        <GiCash className="ion" />
                      </div>
                      <h3>Secure Cash Transfer </h3>
                      {/* <p>Lorem ipsum dolor sit adipisicing dolor sit amet, .</p> */}
                    </div>
                    <div className="visions-box">
                      <div className="vision-icon">
                        <GiRobotGrab className="ion" />
                      </div>
                      <h3>AI Robot Worker </h3>
                      {/* <p>Lorem ipsum dolor sit adipisicing dolor sit amet, .</p> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div id="meet"></div>
          </div>

          <div
            style={{ marginTop: "100px" }}
            data-aos="fade-up"
            data-aos-easing="linear"
            data-aos-duration="500"
            className="abt-data-wrap"
          >
            <div className="abt-box ">
              <h3>Meet Our Team</h3>
              <p className="center boldisnga">
                Leading the Way with Vision and Expertise
              </p>
              <p className="center">
                The Breakaway Co-Founders Are Driving Success in the Delivery
                Industry.
              </p>

              <hr />
              <br />
              <br />
            </div>
            <div className="co-founders-wrap">
              <div className="co-founders">
                <div className="founder">
                  <img src={profileImg} alt="" />
                  <h4>John Doe</h4>
                  <p>CO Founder</p>
                </div>
                <div className="founder">
                  <img src={profileImg} alt="" />
                  <h4>Waseem Khan</h4>
                  <p>CO Founder</p>
                </div>
                <div className="founder">
                  <img src={profileImg} alt="" />
                  <h4>Kavin Andrew</h4>
                  <p>CO Founder</p>
                </div>
              </div>
            </div>
          </div>
          <div className="about-wrap-real">
            <div className="about-wrap">
              {/* <div className="hero-about">
            <div className="about-main-wrap">
              <div className="about-main">
                <p>Let's Know</p>
                <h1>About Us</h1>
                <a href="#SecHero">
                  <button>Go Down</button>
                </a>
                <br />
                <AiOutlineDownCircle size={41} />
              </div>
            </div>
          </div> */}

              {/*  */}

              {/*  */}

              {/* Visions Section Starts Here */}

              <Testimonial />
              <br />
              <br />
              {/* SPONSORS SECTION */}
              {/* <div className="abt-data-wrap">
                <div className="abt-box team-head">
                  <h4>Sponsors</h4>
                  <hr />
                </div>
                <div className="co-founders-wrap">
                  <div className="co-founders">
                    <div className="sponsors">
                      <img
                        src="https://upload.wikimedia.org/wikipedia/commons/thumb/e/eb/ENOC_DL_logo.svg/2560px-ENOC_DL_logo.svg.png"
                        alt=""
                      />
                    </div>
                    <div className="sponsors">
                      <img
                        src="https://i.pinimg.com/originals/72/b0/97/72b0979c1dfe18174d94793823ebd50b.png"
                        alt=""
                      />
                    </div>
                    <div className="sponsors">
                      <img
                        src="https://www.anthemis.com/wp-content/uploads/2021/06/now-money-LOGO.png"
                        alt=""
                      />
                      <br />
                    </div>
                    <div className="sponsors">
                      <img
                        src="https://logos-world.net/wp-content/uploads/2022/05/Daraz-Logo.png"
                        alt=""
                      />
                    </div>
                    <div className="sponsors">
                      <img
                        src="https://upload.wikimedia.org/wikipedia/commons/7/7a/Logonetflix.png"
                        alt=""
                      />
                    </div>
                    <div className="sponsors">
                      <img
                        src="https://www.pk.abbott/etc.clientlibs/abbott-platform/clientlibs/clientlib-site/resources/images/abbott-logo.png"
                        alt=""
                      />
                    </div>
                  </div>
                  <br />
                  <br />
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
