import React from "react";
import { Link } from "react-router-dom";
import "./style.css";
export default function BottomBannerReg() {
  return (
    <>
      <div>
        <div className="bottom-reg-wrap">
          <div className="bottom-reg">
            <div className="bottome-reg-left">
              <h2>Want to register your Business Here! </h2>
              <p>
                If you are looking for a delivery agency to grow your business so click on register button
              </p>
            </div>
            <Link to="/signup">
            <div className="bottom-reg-box">Register Here</div>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}
