import React from "react";
import Footer from "../../Footer/footer";
import Header from "../../navbar/navbar";
import Spacer from "../../spacer-for-header/spacer";
import CompanyBanner from "../company hero/comp-hero";
export default function CompanyOrderPage (){

return(
    <>
    <Header/>
    <Spacer/>
    <CompanyBanner/>
    <Footer/>
    </>
)



}