import React from "react";
import { Route, Routes } from "react-router-dom";

import Main from "../MainPage/main";
import About from "../About/about.jsx";
import Contact from "../rout/Contact";
import Company from "../COMPANY Components/Company Login/company";
import PageNotFound from "../../404/404";
import CompanyPage from "../COMPANY Components/CompanyPageFolder/companyPage";
import Register from "../COMPANY Components/Register/Register";
import Gifts from "../gifts/media/gift";
import Product from "../Product-page/product";
import Product2 from "../Product-page/product2";
import Orders from "../Company/orders";
import CompanyOrders from "../Company/companyOrders";
export default function Rout() {
  return (
    <>
      <Routes>
        <Route path="/" element={<Main />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/company" element={<Company />} />
        <Route path="*" element={<PageNotFound />} />
        <Route path="/companyorder" element={<CompanyPage />} />
        <Route path="/signup" element={<Register />} />
        <Route path="/gifts" element={<Gifts />} />
        <Route path="/selectedgift" element={<Product />} />
        <Route path="/product2" element={<Product2 />} />
        <Route path="/orders" element={<Orders />} />
        <Route path="/ordersCompany" element={<CompanyOrders />} />
      </Routes>
    </>
  );
}