import React from "react";
import Header from "../navbar/navbar";
import Hero from "../heroSection/hero";
import Footer from "../Footer/footer";
import Features from "../features/feature";
import Glimps from "../Glimps/glimps.jsx";
import Options from "../Options/options.jsx";
import SwitchBreakaway from "../SwitchBreakaway/SwitchBreakaway.jsx";
import Spacer from "../spacer-for-header/spacer";
import Feedback from "../Feedback/feedback";
import Laya from "../Collage laya/laya";
import Freights from "../Freights/Freights.jsx";
import BottomBannerReg from "../bottom banner/botbanreg.jsx"
import Square from "../squares/square"
export default function Main() {
  return (
    <>
      <Header />
      <Spacer />
      <Hero />
      <Features />
      <Square />
      <Laya />
      <SwitchBreakaway />
      {/* <Freights/>
      <Options />
      <Glimps /> */}
      <BottomBannerReg/>
      <Feedback />
      <Footer />
    </>
  );
}
