import React, { useState, useEffect } from "react";
import Header from "../../navbar/navbar";
import {
  LockOutlined,
  UserOutlined,
  MailOutlined,
  PhoneOutlined,
} from "@ant-design/icons";
import { Button, Form, Input } from "antd";
import {
  collection,
  query,
  onSnapshot,
  getDocs,
  where,
  addDoc,
} from "firebase/firestore";
import { db } from "../../firebaseconfig";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import Footer from "../../Footer/footer";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import "./register.css";
import Spacer from "../../spacer-for-header/spacer";

export default function RegisterationForm() {
  useEffect(() => {
    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {
      if (user) {
        const uid = user.uid;
        navigate("/companyorder");
      } else {
      }
    });
  });

  const [userName, setuserName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [cover, setCover] = useState("");

  const navigate = useNavigate();
  async function addUser() {
    if (
      userName.length >= 3 &&
      email.length >= 5 &&
      password.length >= 6 &&
      cover.length >= 15
    ) {
      try {
        checkIfAlreadyInPending();
        async function checkIfAlreadyInPending() {
          const q = query(
            collection(db, "pendingAccounts"),
            where("email", "==", email)
          );
          const querySnapshot = await getDocs(q);
          if (querySnapshot.empty) {
            sendToPending();
          } else {
            Swal.fire(
              "Yo've Already Applied!",
              "Please wait for our response or contact us if you have any questions. Thank you.",
              "alert"
            );
          }
        }
      } catch (e) {
        console.log("this ic catch");
      }
    } else {
      Swal.fire(
        "Enter Proper Data!",
        "Please provide authentic data for registration ",
        "warning"
      );
    }
  }

  async function sendToPending() {
    const docRef = await addDoc(collection(db, "pendingAccounts"), {
      name: userName,
      email: email,
      password: password,
      cover: cover,
      phone: phone,
    }).then(
      Swal.fire(
        "Succesfully Applied!",
        " We will respond you In the next 24Hours.",
        "success"
      )
    );
  }

  return (
    <>
      <Header />
      <Spacer />

      <div className="sigin-form-wrap Register-form-wrap ">
        <div className="sigin-form register-form-main">
          <div className="register-notes-right">
            <div className="data-under">
              <h2 className="register-h1">Register As Company</h2>
              <p className="company-p">
                It's Good You are Registering Your Account Here But Must
                Remember You should be a company Otherwise Your account will be
                banned permanently and you would'nt be able to access again
              </p>
              <Link to="/company">
                <button className="log-sign-btn"> Go to login </button>
              </Link>
            </div>
          </div>
          <div className="register-form-left">
            <Form
              name="normal_register"
              className="register-form"
              initialValues={{ remember: true }}
            >
              <Form.Item
                name="name"
                rules={[
                  { required: true, message: "Please Enter Business Name!" },
                ]}
              >
                <Input
                  maxLength={30}
                  prefix={<UserOutlined className="site-form-item-icon" />}
                  type="text"
                  placeholder="Name"
                  value={userName}
                  onChange={(e) => {
                    setuserName(e.target.value);
                  }}
                />
              </Form.Item>
              <Form.Item
                name="email"
                rules={[
                  { required: true, message: "Please input your Email!" },
                ]}
              >
                {/* .css-dev-only-do-not-override-9ntgx0 */}
                <Input
                  prefix={<MailOutlined className="site-form-item-icon" />}
                  placeholder="Email"
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                />
              </Form.Item>
              {/* phone */}
              <Form.Item
                name="call"
                rules={[{ required: true, message: "Please Enter Phone !" }]}
              >
                {/* .css-dev-only-do-not-override-9ntgx0 */}
                <Input
                  prefix={<PhoneOutlined className="site-form-item-icon" />}
                  placeholder="Phone"
                  value={phone}
                  onChange={(e) => {
                    setPhone(e.target.value);
                  }}
                />
              </Form.Item>

              <Form.Item
                name="password"
                rules={[
                  { required: true, message: "Please input your Password!" },
                ]}
              >
                <Input
                  showCount
                  prefix={<LockOutlined className="site-form-item-icon" />}
                  type="password"
                  placeholder="Type Password (8 digits required )"
                  value={password}
                  onChange={(e) => {
                    setPassword(e.target.value);
                  }}
                />
              </Form.Item>

              <Form.Item
                name="letter"
                rules={[
                  {
                    required: true,
                    message: "Please input cover/Intro",
                  },
                ]}
              >
                <Input.TextArea
                  style={{ resize: "none", maxHeight: "100px" }}
                  placeholder="Write a cover letter (30 words required )"
                  maxLength={100}
                  className="site-form-item-icon my-regist-textarea"
                  value={cover}
                  onChange={(e) => {
                    setCover(e.target.value);
                  }}
                />
              </Form.Item>
              {/* 
              <Form.Item>
                <a
                  className="register-form-forgot"
                  onClick={() => {
                    GoToSignup();
                  }}
                >
                  Forgot password
                </a>
              </Form.Item> */}

              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="register-form-button-custom"
                  onClick={addUser}
                >
                  Apply To Register
                </Button>
                <br />
                <br />
                <Link to="/company">Go to login</Link>
              </Form.Item>
            </Form>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
