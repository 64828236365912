import React from "react";
import "./features.css";

import gift from "./media/gift.png";
import company from "./media/company.png";
import order from "./media/order.png";

export default function Features() {
  return (
    <>
      <div className="featuresWrap">
        <div className="features">
          <div className="featureOne featureBox">
            <div className="img-wrapfeatur">
              <img
                src={gift}
                width={"70px"}
                height={"100%"}
                alt=""
                className="img-class"
              />
            </div>
            <h3>Send Gifts</h3>
            <hr className="features-hr" />
            <p>
              Sending gifts to your loved ones has never been easier. With
              Breakaway Delivery, you can now share the joy of gift-giving with
              your friends and family, no matter where they are in the world.
            </p>
          </div>

          <div className="featureTwo featureBox featHIghlightes">
            <div className="img-wrapfeatur">
              <img
                src={company}
                width={"70px"}
                height={"100%"}
                style={{ marginBottom: "10px" }}
                alt=""
              />
            </div>
            <h3>Register Company</h3>
            <hr className="features-hr" />
            <p>
              Join the Breakaway family today and take advantages, Sign up now
              and experience the difference that comes with working with one of
              the leading delivery companies in the industry.
            </p>
          </div>

          <div className="featureThree featureBox">
            <div className="img-wrapfeatur">
              <img src={order} width={"70px"} alt="" />
            </div>
            <h3>Custom Order</h3>
            <hr className="features-hr" />
            <p>
              Breakaway believes that delivery services should be tailored to
              meet your unique requirements. That's why we offer to choose the
              solution that best fits your needs.
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
