import React from "react";
import "./difference.css";
export default function Difference() {
  return (
    <>
      <div>
        <div className="df-main-wrap">
          <div className="df-main">
            <div className="df-head">
              <h1>Set up your business</h1>
            </div>
            <div className="df-body-wrap">
              <div className="df-body">
                <p className="dfbdhd-data">
                  Getting started with our breakaway is quick
                  and easy! All you need to do is fill out our simple
                  registration form. The form will ask for basic information
                  such as your name, contact information, and details about your
                  business. Once you have submitted the form, our team will
                  review and process your request. Before you know it, you will
                  be a registered user of our delivery services and ready to
                  enjoy the many benefits we have to offer. Don't wait, start
                  your registration today and join our community of satisfied
                  customers!
                </p>
                <div className="df-box">
                  <h2> <span className="breakaway"> Breakaway</span> work flow </h2>
                  <p>
                    At our Breakaway delivery service, we have a strict set of
                    rules to ensure a smooth and efficient delivery process for
                    all our clients. Here are five key points you should know
                    about our rules of strictness:
                  </p>

                  <ul>
                    <li>
                      Compliance: We strictly adhere to all local, national, and
                      international regulations, laws and guidelines related to
                      delivery services to ensure compliance and avoid any legal
                      issues.
                      <br /> <br />
                    </li>
                    <li>
                      Accuracy: We have strict procedures in place to ensure
                      accurate delivery of packages to the correct recipient.s
                      <br /> <br />
                    </li>
                    <li>
                      Safety and security: We take the safety and security of
                      our clients' packages seriously. Our strict security
                      measures ensure that all packages are protected during
                      transit.
                      <br /> <br />
                    </li>
                  </ul>
                  {/* <p>
                    This m ipsum dolor sit amet consectetur. LLCs, corporations,
                    and partner Breakaway is a good place to start.
                  </p> */}
                </div>
                {/* 2 start */}

                <div className="df-box">
                  <h2>
                    Advantages for registered businesses
                    </h2>
                  <p>
                    As a registered user of our breakaway, you
                    can expect a higher level of service and priority treatment.
                    Our goal is to make the delivery process as seamless and
                    convenient as possible for our registered users. You will
                    have access to dedicated support teams, customizable
                    shipping options, and real-time tracking information to stay
                    informed about the status of your deliveries. In addition,
                    registered users are eligible for exclusive promotions and
                    discounts on our delivery services. We understand the
                    importance of delivering packages on time, every time, and
                    prioritize the needs of our registered users to ensure they
                    receive the fastest and most efficient service possible.
                    Sign up today and experience the benefits of being a
                    registered user of our breakaway!
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
