import React, { useState } from "react";
import "./orders.css";
import { db } from "../firebaseconfig";
import { collection, addDoc, Timestamp } from "firebase/firestore";
import Swal from "sweetalert2";
import Footer from "../Footer/footer";
import { Link } from "react-router-dom";
import Header from "../navbar/navbar";
import Spacer from "../spacer-for-header/spacer";

const Orders = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [pick, setPick] = useState("");
  const [payment, setPayment] = useState("");
  const [drop, setDrop] = useState("");
  const [pDetails, setPDetails] = useState("");
  const [loader, setLoader] = useState(false);

  const removeData = () => {
    setName("");
    setEmail("");
    setPhone("");
    setPick("");
    setDrop("");
    setPDetails("");
  };

  let SaveData = async (e) => {
    e.preventDefault();
    if (
      name === "" ||
      email === "" ||
      phone === "" ||
      pick === "" ||
      drop === "" ||
      pDetails === ""
    ) {
      Swal.fire({
        icon: "error",
        title: "Something Wrong...",
        text: "All Data is Necessary.",
      });
    } else {
      let collectionRef = collection(db, "orders");
      setLoader(true);
      const docRef = await addDoc(collectionRef, {
        name: name,
        email: email,
        phone: phone,
        pick: pick,
        drop: drop,
        payment:payment,
        additionalDetails: pDetails,
        date: Timestamp.fromDate(new Date()),
        seconds : Timestamp.fromDate(new Date()).seconds,
        orderType: "normal",
      });
      setLoader(false);
      Swal.fire({
        icon: "success",
        title: "Thanks for ordering!",
        text: "We have received your request and are processing it now. ",
      })
      removeData();
    }
  };

  return (
    <>
      <Header />
      <Spacer />
      <div>
        <div className="container">
          <div className="row">
            <div className="col-12 offset-0 col-md-10 offset-md-1 col-lg-8 offset-lg-2">
              <br />
              <div className="card bg-light text-center mb-5">
                <article className="card-body ">
                  <h4 className="card-title my-3 text-center">ORDER NOW</h4>

                  <form>
                    <div className="form-group input-group">
                      <div className="input-group-prepend"></div>
                      <input
                        // name
                        className="form-control order-form"
                        placeholder="Your Name"
                        type="text"
                        value={name}
                        onChange={(e) => {
                          setName(e.target.value);
                        }}
                      />
                    </div>
                    {/* form-group// */}
                    <div className="form-group input-group">
                      <div className="input-group-prepend"></div>
                      <input
                        // email
                        className="form-control order-form"
                        placeholder="Email"
                        type="email"
                        value={email}
                        onChange={(e) => {
                          setEmail(e.target.value);
                        }}
                      />
                    </div>
                    {/* form-group// */}
                    <div className="form-group input-group">
                      <div className="input-group-prepend"></div>
                      <input
                        // phone
                        className="form-control order-form"
                        placeholder="Phone Number"
                        type="number"
                        value={phone}
                        onChange={(e) => {
                          setPhone(e.target.value);
                        }}
                      />
                    </div>
                    {/* form-group// */}
                    <div className="form-group input-group">
                      <div className="input-group-prepend"></div>
                      <input
                        // Pick Location
                        className="form-control order-form"
                        placeholder="Pick Location"
                        type="text"
                        value={pick}
                        onChange={(e) => {
                          setPick(e.target.value);
                        }}
                      />
                    </div>
                    {/* form-group// */}
                    <div className="form-group input-group">
                      <div className="input-group-prepend"></div>
                      <input
                        // Drop Location
                        className="form-control order-form"
                        placeholder="Drop Location"
                        type="text"
                        value={drop}
                        onChange={(e) => {
                          setDrop(e.target.value);
                        }}
                      />
                    </div>
                {/* how would you pay */}
                    <div style={{textAlign:'left',cursor:'pointer', padding:'8px 0px'}}>
          How would you pay : &nbsp;
           <input type="radio" name="pay-cur" value={"PKR"}  onChange={(e)=>{setPayment(e.target.value)}}       style={{cursor:'pointer'}} /> PKR   &nbsp;&nbsp;
           <input type="radio" name="pay-cur" value={"DIR"}    onChange={(e)=>{setPayment(e.target.value)}}  style={{cursor:'pointer'}} /> AED 
          </div>
                    {/* form-group// */}
                    <div className="mb-3">
                      <textarea
                        className="form-control"
                        id="exampleFormControlTextarea1"
                        placeholder="Product Details"
                        rows={3}
                        defaultValue={""}
                        value={pDetails}
                        onChange={(e) => {
                          setPDetails(e.target.value);
                        }}
                      />
                    </div>
                    <div className="form-group">
                      <button
                        type="submit"
                        className="btn btn-primary btn-block"
                        onClick={SaveData}
                        disabled={loader}
                      >
                        {loader ? (
                          <div className="spinner-border"></div>
                        ) : (
                          "Order Now"
                        )}
                      </button>
                    </div>
                    {/* form-group// */}
                    <p className="text-center">
                      Order As <Link to={"/ordersCompany"}>Company</Link>
                    </p>
                  </form>
                </article>
              </div>
              {/* card.// */}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Orders;
