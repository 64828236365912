import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import "bootstrap/dist/css/bootstrap.min.css";
// import Logo from "./indexwhite1.png";
import Logo from "./LAST.png";
import "./nav.css";
import { Link } from "react-router-dom";
import { onAuthStateChanged, getAuth, signOut } from "firebase/auth";
import { useEffect, useState } from "react";
// Ant d
import {  notification } from 'antd';

// import Hero from "../heroSection/hero"
function Header() {
  const [isUser, setUser] = useState(false);
  const auth = getAuth();
  function GoToCompany() {}
  function logout() {
    signOut(auth)
      .then(() => {
         openNotificationWithIcon('success')
      })
      .catch((error) => {
        // An error happened.
      });
  }
  useEffect(() => {
    
      window.scrollTo(0, 0);
    
    onAuthStateChanged(auth, (user) => {
      if (user) {
        setUser(true);
      } else {
        setUser(false);
        }
    });
  }, []);


  const [api, contextHolder] = notification.useNotification();
  const openNotificationWithIcon = (type) => {
    api[type]({
      message: 'Signed Out',
      description:
        "You have successfully signed out",
    });

  }

  return (
    <>

<div className="max-width-wrap-header">
      <div className="max-width-header">

      <Navbar
        className="main-nav fixed-top shadow"
        style={{ padding: "10px" }}
        collapseOnSelect
        expand="lg"
      >
        <Container>
          <Navbar.Brand href="#home" className="w3-animate-left">
            <img src={Logo} alt="" className="mainLogo" />
          </Navbar.Brand>
          <Navbar.Toggle
            aria-controls="responsive-navbar-nav"
            className="left-wrap"
            />
        </Container>
        <div className="heasder2 w3-animate-right">
          <Navbar.Collapse
            id="responsive-navbar-nav"
            className="nav-left-options"
          >
            <Nav className="me-auto">
              {/* <Nav.Link> */}
                  <Link to="/" className="nav-Linka">
                <li className="navbar-links">
                    Home

                </li>
                    </Link>
              {/* </Nav.Link> */}

              {/* <Nav.Link> */}
                  <Link to="/companyorder" className="nav-Linka">
                <li className="navbar-links" onClick={GoToCompany}>
                    Company
                </li>
                     </Link>
              {/* </Nav.Link> */}

              {/* <Nav.Link eventKey={2}> */}
                  <Link to="/gifts" className="nav-Linka">
                <li className="navbar-links">
                    Gifts
                </li>
                    </Link>
              {/* </Nav.Link> */}

              {/* <Nav.Link> */}
                  <Link to="/contact" className="nav-Linka">
                <li className="navbar-links">
                    Contact{" "}
                </li>
                  </Link>
              {/* </Nav.Link> */}

              {/* <Nav.Link eventKey={2}> */}
                  <Link to="/about" className="nav-Linka">
                <li className="navbar-links" >
                    About
                </li>
                  </Link>
              {/* </Nav.Link> */}
              {!isUser ? (
                // <Nav.Link eventKey={2}>
                <Link to="/company" className="nav-Linka">
                  <li className="navbar-links">
                      login
                  </li>
                      </Link>
                // </Nav.Link>
                ) : null}
              {!isUser ? (
                <Nav.Link eventKey={2}>
                  {/* <li className="navbar-links">
                    <Link to="/register">Signup</Link>
                  </li> */}
                </Nav.Link>
              ) : (
                // <Nav.Link eventKey={2}>
                <Link to="" className="nav-Linka">
                  <li
                    onClick={() => {
                      logout();
                    }}
                    className="navbar-links"
                    >
                      Logout
                  </li>
                      </Link>
                // </Nav.Link>
                )}
            </Nav>
          </Navbar.Collapse>
      
       
                </div>
      </Navbar>
     
    {contextHolder}
      
      </div>
      </div>
      </>
  );
}

export default Header;
