import React from "react";
import { Layout } from "antd";
import "./laya.css";
import Aos from "aos";
Aos.init();

function Laya() {
  return (
    <>
      <br />
      <br />
      <div className="ultra-all-wrap">
        <div className="ultra-all">
          <div className="laya-head-wrap">
            <div data-aos="fade-up" className="laya-head">
              <h1>
                {" "}
                <span className="breakaway">Breakaway </span> have passionate
                employees
              </h1>
              <hr />
              <p>
                At Breakaway, we take pride in our hard-working
                employees who strive to make every delivery experience a
                success. From the moment you place your order to the final
                delivery, our team is dedicated to ensuring your satisfaction.
                Our drivers work tirelessly to make sure your packages are
                delivered on time and in perfect condition. Customer happiness
                is our top priority and we will go above and beyond to make sure
                you are satisfied with our services. <br />
                Our passionate employees at Breakaway are the heart and
              soul of our company. Their dedication and attention to detail is
              what sets us apart from the competition. Whether it's the care
              they take when handling your packages, or their commitment to
              providing exceptional customer service, our employees truly embody
              the spirit of Breakaway. 
              </p>
            </div>
          </div>

          {/* <div className="laya-new-container">
            <div className="laya-new-box">
              <img src="https://m.media-amazon.com/images/G/01/Last_Mile/DSP/DA_Hiring/DA_Support_Image_4.jpg" />
            </div>
            <div className="laya-new-box">
              <img src="https://cdn.shrm.org/image/upload/c_crop%2Ch_705%2Cw_1254%2Cx_0%2Cy_0/c_fit%2Cf_auto%2Cq_auto%2Cw_767/v1/Legal%20and%20Compliance/food_delivery2m_q7bucv?databtoa=eyIxNng5Ijp7IngiOjAsInkiOjAsIngyIjoxMjU0LCJ5MiI6NzA1LCJ3IjoxMjU0LCJoIjo3MDV9fQ%3D%3D" />
            </div>
            <div className="laya-new-box">
              <img src="https://i.guim.co.uk/img/media/2796d3ae1c11bede84554c1696f405ecaf203a4c/0_49_4128_2479/master/4128.jpg?width=1200&quality=85&auto=format&fit=max&s=4abb9405060d356523a3139adc468d81" />
            </div>
            <div className="laya-new-box">
              <img src="https://m.media-amazon.com/images/G/01/wfs/Flex-driver-image._CB1198675309_.png" />
            </div>
          </div>
          <div className="laya-down">
            <p>
              Our passionate employees at Breakaway are the heart and
              soul of our company. Their dedication and attention to detail is
              what sets us apart from the competition. Whether it's the care
              they take when handling your packages, or their commitment to
              providing exceptional customer service, our employees truly embody
              the spirit of Breakaway. 
              </p>
          </div> */}
        </div>
      </div>
    </>
  );
}

export default Laya;
