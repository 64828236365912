import React, { useState, useEffect } from "react";
import Header from "../../navbar/navbar";
import { LockOutlined, UserOutlined } from "@ant-design/icons";
import { Button, Form, Input } from "antd";
import {
  collection,
  query,
  where,
  onSnapshot,
  getDocs,
} from "firebase/firestore";
import { db } from "../../firebaseconfig";
import Footer from "../../Footer/footer";
import Swal from "sweetalert2";
import "./company.css";
import { Link } from "react-router-dom";
import {
  getAuth,
  onAuthStateChanged,
  signInWithEmailAndPassword,
} from "firebase/auth";
import { useNavigate } from "react-router-dom";
import Spacer from "../../spacer-for-header/spacer";
import "./companyAuth.css"

export default function Company() {
  useEffect(() => {
    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {
      if (user) {
        const uid = user.uid;
        navigate("/companyorder");
      } else {
      }
    });
  });

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [company, setCompany] = useState("");
  const navigate = useNavigate();

  function SignIn() {
    const auth = getAuth();

    try {
      const q = query(
        collection(db, "registerdCompanies"),
        where("cEmail", "==", email)
      );
      getDocs(q).then((querySnapshot) => {
        if (querySnapshot.empty) {
          Swal.fire({
            icon: "error",
            title: "Not Registered As Company",
            text: "First you need to apply for registration",
          });
        } else {
          const unsubscribe = onSnapshot(q, (querySnapshot) => {
            querySnapshot.forEach((doc) => {
              let data = doc.data();
              setCompany(company);
            });
          });
          signInWithEmailAndPassword(auth, email, password)
            .then((userCredential) => {
              const user = userCredential.user;
              navigate("/companyorder");
            })
            .catch((error) => {
              const errorCode = error.code;
              const errorMessage = error.message;
              const wrongEmaPasPopUp = () => {
                Swal.fire({
                  icon: "error",
                  title: "Something Wrong...",
                  text: "Please enter correct email & password",
                });
              };
              wrongEmaPasPopUp();
            });
    return () => unsubscribe();
        
          }
      });
    } catch {
      console.log("s");
    }
  }

  return (
    <>
      <Header />
      <Spacer />
      <div className="max-width-wrap">
        <div className="sigin-form-wrap">
          <div className="sigin-form">
            <div className="login-notes-right">
              <div className="data-under">
                <h2 className="login-h1">Login</h2>
                <p className="company-p">
                  To make orders as a company, you will first need to log in to
                  the platform or website provided by the supplier. This is
                  typically done by entering your login credentials, which will
                  consist of a unique username and password that have been
                  assigned to you or created by you during the registration
                  process
                </p>
                <Link to="/signup">
                  <button className="log-sign-btn">
                    {" "}
                    Register An Account{" "}
                  </button>
                </Link>
              </div>
            </div>
            {/* <h1 className="left-login-hidden">Login</h1> */}
            <div className="login-form-left">
              <Form
                name="normal_login"
                className="login-form"
                initialValues={{ remember: true }}
              >
                <Form.Item
                  name="username"
                  rules={[
                    { required: true, message: "Please Enter Your Email!" },
                  ]}
                >
                  <Input
                    prefix={<UserOutlined className="site-form-item-icon" />}
                    placeholder="Email"
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                  />
                </Form.Item>
                <Form.Item
                  name="password"
                  rules={[
                    { required: true, message: "Please input your Password!" },
                  ]}
                >
                  <Input
                    prefix={<LockOutlined className="site-form-item-icon" />}
                    type="password"
                    placeholder="Password"
                    onChange={(e) => {
                      setPassword(e.target.value);
                    }}
                  />
                </Form.Item>
             

                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="login-form-button-custom"
                    onClick={SignIn}
                  >
                    Log in
                  </Button>
                  <br />
                  <br />
                  Or <Link to="/signup"> Register now!</Link>
                </Form.Item>
              </Form>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
