import React, { useState, useEffect } from "react";
import "./the-product-style.css";
import Header from "../navbar/navbar";
import Spacer from "../spacer-for-header/spacer";
import { useLocation, useNavigate } from "react-router-dom";
import { db } from "../firebaseconfig";
import { MdNavigateNext } from "react-icons/md";
import Slider from "react-slick";
import "../gifts/media/product-image-slider/caras.css";
import {
  collection,
  query,
  where,
  onSnapshot,
  addDoc,
  limit,
  startAfter,
  Timestamp
} from "firebase/firestore";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Swal from "sweetalert2";

export default function Product() {
  const settings = {
    infinite: true,
    speed: 700,
    slidesToShow: 1,
    autoplay: true,
    autoplaySpeed: 1000,
    dots: true,
  };

  const [payment, setpayment] = useState();
  const [lgShow, setLgShow] = useState(false);
  const [getData, setGetData] = useState([]);
  const [productImages, setProductImages] = useState(null);
  const [modalName, setModalName] = useState("");
  const [modalEmail, setModalEmail] = useState("");
  const [modalPhone, setModalPhone] = useState("");
  const [modalDrop, setModalDrop] = useState("");
  const [ModalDropName, setModalDropName] = useState("");
  const [ModalDropEmail, setModalDropEmail] = useState("");
  const [paginationNumber, setPaginationNumber] = useState("");
  const [modalAdditionalDetails, setModalAdditionalDetails] = useState("");
  const [loader, setLoader] = useState(false);
  const [otherData, setOtherData] = useState([]);
  const [productCondtion, setProductCondtion] = useState(true);
  const [orderingNo, setOrderingNo] = useState(20);
  const location = useLocation();
  const queryData = location.state;
  const navigate = useNavigate();

  const getSelectedData = () => {
    let collectionRef = collection(db, "allProducts");
    let collectionQuery = where("time", "==", queryData.dataTime);
    const q = query(collectionRef, collectionQuery);
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const alldata = [];
      querySnapshot.forEach((doc) => {
        let data = doc.data();
        alldata.push(data);
        setProductImages(data.image);
      });
      setGetData(alldata);
    });
  };

  useEffect(() => {
    getSelectedData();
    getOtherData();
  }, []);

  const saveData = async () => {
    let selectedProduct = getData[0];
    if (
      modalName === "" ||
      modalEmail === "" ||
      modalPhone === "" ||
      modalDrop === "" ||
      modalAdditionalDetails === ""
    ) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Some Data are Missing!",
      });
    } else {
      let colectionRef = collection(db, "giftOrder");
      setLoader(true);
      const docRef = await addDoc(colectionRef, {
        name: modalName,
        email: modalEmail,
        recipientName:ModalDropName,
        recipientEmail:ModalDropEmail,
        phone: modalPhone,
        payment:payment,
        pick: "Breakaway Gift Center",
        drop: modalDrop,
        date: Timestamp.fromDate(new Date()),
        seconds : Timestamp.fromDate(new Date()).seconds,
        additionalDetails: modalAdditionalDetails,
        image: productImages,
        productName: selectedProduct.name,
        productPrice: selectedProduct.price,
      });
      setLgShow(false);
      setLoader(false);
      Swal.fire({
        icon: "success",
        title: "Thanks for ordering!",
        text: "We have received your request and are processing it now. ",
      });
    }
    setLoader(false);
  };

  function getOtherData() {
    let collectionRef = collection(db, "allProducts");
    let collectionQuery = where("time", "!=", queryData?.dataTime);
    const limitation = limit(orderingNo);
    const q = query(collectionRef, collectionQuery, limitation);
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      let lastInvisible = querySnapshot.docs[querySnapshot.docs.length - 1];
      setPaginationNumber(lastInvisible);
      const alldata = [];
      const allNameData = [];
      querySnapshot.forEach((doc) => {
        let data = doc.data();
        alldata.push(data);
      });
      setOtherData(alldata);
    });

    return () => unsubscribe();
  }

  const getMoreProduct = () => {
    let collectionRef = collection(db, "allProducts");
    let collectionQuery = where("time", "!=", queryData?.dataTime);
    const limitation = limit(orderingNo);
    let paginationQuery = startAfter(paginationNumber);
    const q = query(
      collectionRef,
      collectionQuery,
      limitation,
      paginationQuery
    );
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      let lastInvisible = querySnapshot.docs[querySnapshot.docs.length - 1];
      setPaginationNumber(lastInvisible);
      remainProductChecker();
      const alldata = [];
      querySnapshot.forEach((doc) => {
        let data = doc.data();
        alldata.push(data);
      });
      setOtherData(otherData.concat(alldata));
    });

    return () => unsubscribe();
  };

  function remainProductChecker() {
    let collectionRef = collection(db, "allProducts");
    let collectionQuery = where("time", "!=", queryData?.dataTime);
    const limitation = limit(orderingNo);
    let paginationQuery = startAfter(paginationNumber);
    const q = query(
      collectionRef,
      collectionQuery,
      limitation,
      paginationQuery
    );
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      querySnapshot.size > orderingNo - 1
        ? setProductCondtion(true)
        : setProductCondtion(false);
    });

    return () => unsubscribe();
  }

  const selectedData = (time, name) => {
    navigate("/product2", { state: { dataTime: time, dataName: name } });
  };

  return (
    <>
      <Header />
      <Spacer />
      <Modal
        size="lg"
        show={lgShow}
        onHide={() => setLgShow(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            Order details
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="form-floating mb-3">
            <input
              type="text"
              className="form-control"
              id="floatingInput"
              placeholder="Name"
              value={modalName}
              onChange={(e) => {
                setModalName(e.target.value);
              }}
            />
            <label htmlFor="floatingInput">Your Name</label>
          </div>
          <div className="form-floating mb-3">
            <input
              type="email"
              className="form-control"
              id="floatingPassword"
              placeholder="Email"
              value={modalEmail}
              onChange={(e) => {
                setModalEmail(e.target.value);
              }}
            />
            <label htmlFor="floatingPassword">Email</label>
          </div>
          <div className="form-floating mb-3">
            <input
              type="number"
              className="form-control"
              id="floatingPassword"
              placeholder="Password"
              value={modalPhone}
              onChange={(e) => {
                setModalPhone(e.target.value);
              }}
            />
            <label htmlFor="floatingPassword">Phone</label>
          </div>
          <div style={{textAlign:'left',cursor:'pointer', padding:'8px 0px'}}>
          How would you pay : &nbsp;
           <input type="radio" name="pay-cur" value={"PKR"}  onChange={(e)=>{setpayment(e.target.value)}}       style={{cursor:'pointer'}} /> PKR   &nbsp;&nbsp;
           <input type="radio" name="pay-cur" value={"DIR"}    onChange={(e)=>{setpayment(e.target.value)}}  style={{cursor:'pointer'}} /> AED  
           
          
          </div>
            <h4>Recipient Detail</h4>
                <div className="recip-box" style={{width:'auto' ,display:'flex',justifyContent:'center'}}>
          <div className="recip-box-left" style={{width:'95%'}}>
          <div className="form-floating mb-3">

            <input 
            style={{width:"95%"}}
              type="text"
              id="floatingInput"
              placeholder="Name"
              className="form-control"
              value={ModalDropName}
              onChange={(e) => {
                setModalDropName(e.target.value);
              }}
              />
            <label htmlFor="floatingPassword">Recipient Name</label>
          </div>
          </div>

          <div className="recip-box-left" style={{width:'100%',textAlign:'right'}}>

          <div className="form-floating mb-3">
            <input
              type="text"
              className="form-control"
              id="floatingPassword"
              placeholder="email"

              style={{width:'100%'}}
              value={ModalDropEmail}
              onChange={(e) => {
                setModalDropEmail(e.target.value);
              }}
              />
            <label htmlFor="floatingPassword">Recipient Email</label>
          </div>
              </div>

          </div>
          <div className="form-floating mb-3">
            <input
              type="text"
              className="form-control"
              placeholder="Address"
              value={modalDrop}
              onChange={(e) => {
                setModalDrop(e.target.value);
              }}
            />
            <label htmlFor="floatingPassword">Recipient Address</label>
          </div>

          <div className="form-floating mb-3">
            <input
              type="text"
              className="form-control"
              placeholder="Password"
              value={modalAdditionalDetails}
              onChange={(e) => {
                setModalAdditionalDetails(e.target.value);
              }}
            />
            <label htmlFor="floatingPassword">Additional Details</label>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" className="giftOrderBtn" onClick={saveData} disabled={loader}>
            {loader ? <div className="spinner-border"></div> : "Confirm order"}
          </Button>
        </Modal.Footer>
      </Modal>
      {getData.map((currentEl, index) => {
        return (
          <div className="prod-wrap" key={index}>
            <div className="left-of-selected-prod shadow">
              <div className="container-prod">
                <div className="medias-wrap">
                  <div className="product-image">
                    <div className="caras-prod-wrap">
                      <Slider
                        {...settings}
                        className="main-gift-image-div-box-wrap"
                      >
                        {productImages?.map((currentData, index) => {
                          return (
                            <div className="gift-img-div" key={index}>
                              <img src={currentData} alt="product-image" />
                            </div>
                          );
                        })}
                      </Slider>
                    </div>
                  </div>
                </div>
                <div className="product-details">
                  <header>
                    <h1 className="title">{currentEl.name}</h1>
                    <div className="price">
                      <span className="current">{currentEl.price}</span>
                    </div>
                  </header>
                  <article>
                    <h5>Description</h5>
                    <p>{currentEl.detail}</p>
                  </article>
                  <div className="controls"></div>
                  <div className="footer codespan-div">
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={() => {
                        setLgShow(true);
                      }}
                    >
                      Order Now
                    </button>
                  </div>
                </div>
              </div>
              <hr />

              {/* Bottom section start */}

              <div className="cards-wrap">
                <div className="cards-main mt-4">
                  {/* Loop Data start*/}

                  {otherData?.map((currentEl, index) => {
                    return (
                      <div
                        className="prod-box-wrap"
                        key={index}
                        onClick={() => {
                          selectedData(currentEl.time, currentEl.name);
                        }}
                      >
                        <div className="prod-box">
                          <div className="box-img-wrap">
                            <img
                              src={currentEl.image[0]}
                              alt="product-image"
                              className="prod-img"
                            />
                          </div>
                          <div className="box-text-wrap">
                            <h6 className="prod-title-text">
                              {currentEl.name}
                            </h6>
                            <div className="card-down">
                              <button className="buy-btn-s">
                                View
                                <MdNavigateNext color="white" size={20} />
                              </button>
                              <p>{currentEl.price}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}

                  {/* Loop data end */}
                </div>
              </div>
              {productCondtion ? (
                <div className="container-fluid my-3">
                  <div className="row">
                    <div className="col text-center">
                      <button className="show-more" onClick={getMoreProduct}>
                        Show More
                      </button>
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        );
      })}
    </>
  );
}
