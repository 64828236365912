import React from "react";
import { Link } from "react-router-dom";
import "./hero2.css";
import heroVector from '../medias/herovector.png'
import heroVector2 from '../medias/new phone.png'
export default function Hero() {
  return (
    <>
      <div className="ultra">
        <div className="hero-wrap">
          <div className="hero-main"
          >
            <div
              style={{
                width: "100vw",
                position: "absolute",
                display: "flex",
                justifyContent: "flex-end"
              }}
            >
              <div className="animated-image">
                <img width={1000} src={heroVector} alt="Hero Vector" />
              </div>
            </div>
            <div className="fin-main w3-animate-bottom">

              <h1 className="hero-h1">
                <span className="hero-quote">
                  Your vision, We delivered
                </span>
                <br />
                Breakaway Delivery <span className="content2">
                  <br />A step to get forward
                </span>
              </h1>

              {/* <div className="heroHr"></div> */}
              <div className="animated-image2">
                <img width={130} style={{ position:'absolute', top:-100,right:-40 }} src={heroVector2} alt="Hero Vector" />
              </div>
              <p className="hero-p">
              
                Breakaway is the easy & smartest delivery service ever , and we
                understand the importance of fast, reliable, and efficient
                delivery services. <br />That's why we've designed our delivery
                services to meet all of your shipping needs.
              </p>
              <div className="hero-buttons-wrap">
                <Link to={"./orders"}>
                  <button className="cr-btn"> ORDER NOW </button>
                </Link>
                <Link to={"/contact"}>
                  <button className="tr-btn">CONTACT US</button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
