import React from "react";
import "./SwitchBreakaway.css";
import { BsWhatsapp } from "react-icons/bs";
import { AiOutlineTrademarkCircle } from "react-icons/ai";
import { SiFastapi } from "react-icons/si";
import {
  FcCurrencyExchange,
  FcRegisteredTrademark,
  FcCallback,
} from "react-icons/fc";
// import woman from "./media/Young-woman-with-headscarf-presenting-idea-while-looking-smiling-towards-vector-PNG.png";
import woman from "../medias/2751942_256113-P4J8UW-480-removebg-preview.png";
// import woman from "../medias/big delivery bo.png";
import Aos from "aos";
Aos.init();

export default function SwitchBreakaway() {
  return (
    <>
      <div className="laya-ultra-wrap">
        <div className="laya-ultra">
          <div className="SwitchBreakaway-main-wrap">
            <div className="SwitchBreakaway-main">
              <div data-aos="fade-right" className="SwitchBreakaway-left">
                <img src={woman} width={"300px"}  style={{marginBottom:'60px'}} alt="" />
              </div>
              <div data-aos="fade-up" className="SwitchBreakaway-right">
                <h3>
                  Why Should Chose{" "}
                  <span className="breakaway-w">Breakaway</span>
                </h3>
                <p className="switch-brkhead-p">
                  When it comes to choosing a delivery service, trust and
                  convenience are essential. That's why you should choose
                  Breakaway for all your delivery needs. Our cash on
                  delivery option eliminates the need for pre-payment or online
                  transactions, giving you peace of mind. Booking a delivery is
                  easy with our convenient phone call ordering system. Breakaway
                  has established itself as a trusted platform for
                  reliable and trustworthy delivery services.
                </p>
                <div className="SwitchBreakaway-right-down">
                  <div className="sbrown-wrap">
                    <div className="sbrown">
                      <SiFastapi
                        color="rgb(33, 150, 243)"
                        className="sbrown-icon"
                      />
                      <p>Quick response for everyone</p>
                    </div>
                  </div>
                  <div className="sbrown-wrap">
                    <div className="sbrown">
                      <FcCallback className="sbrown-icon" />
                      <p>Maker order via phone call</p>
                    </div>
                  </div>
                  <div className="sbrown-wrap">
                    <div className="sbrown">
                      <FcCurrencyExchange className="sbrown-icon" />
                      <p>Payment In Darham and PKR </p>
                    </div>
                  </div>
                  <div className="sbrown-wrap">
                    <div className="sbrown">
                      <FcRegisteredTrademark className="sbrown-icon" />
                      <p>Registered from MCA</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
