import React, { useState } from "react";
import Swal from "sweetalert2";
import { db } from "../firebaseconfig";
import { collection, addDoc } from "firebase/firestore";
import "./feedback.css";
const Feedback = () => {
  const [name, setName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [loader, setLoader] = useState(false);

  const removeData = () => {
    setName("");
    setEmail("");
    setLastName("");
    setMessage("");
  };

  const handleSubmit = async () => {
    if (name === "" || lastName === "" || email === "" || message === "") {
      Swal.fire({
        icon: "error",
        title: "Fill Required Fields",
        text: "All data is required",
      });
    } else {
      let collectionRef = collection(db, "feedback");
      setLoader(true);
      await addDoc(collectionRef, {
        firstName: name,
        lastName: lastName,
        email: email,
        feedback: message,
        time: new Date().getTime(),
      });
      setLoader(false);
      Swal.fire({
        icon: "success",
        title: "Feedback Submitted",
        text: "Thanks for your feedback",
      });
      removeData();
    }
  };

  return (
    <>
      <div className="all-wrap-feedback">
        <div className="feedbac-wrap">
          <div className="right-of-feedback">
            <div className="fbform-main-wrap">
              <div className="left-of-feedback">
                <div className="give-us">
                  <h2>
                    We Need Your Valuable Feedbacks To Improve Our Services{" "}
                  </h2>
                  <hr />
                  <p>
                    Breakaway value the opinions of our customers and
                    are always looking for ways to improve our services. If you
                    have recently used our delivery services, we would love to
                    hear your feedback. Whether you have a suggestion for how we
                    can better serve you, or simply want to share your
                    experience, your input is important to us. Please take a
                    moment to fill out our feedback form and let us know how
                    we're doing. Your comments will help us to continue
                    delivering the high-quality services that our customers have
                    come to expect from Breakaway.
                  </p>
                </div>
              </div>
              <div className="fbform-main">
                <h1>Give Feedback!</h1>
                {/* <p>
                  Lorem ipsum, dolor sit amet  adipisicing amet
                  consectetur adipisicing elit.
                </p> */}

                <div className="names-inp-fdbk">
                  <input
                    className="inp-cus-focus"
                    type="text"
                    name="firstname"
                    required
                    placeholder="First Name"
                    value={name}
                    onChange={(e) => {
                      setName(e.target.value);
                    }}
                  />
                  <input
                    type="text"
                    name="lastname"
                    required
                    placeholder="Last Name"
                    value={lastName}
                    onChange={(e) => {
                      setLastName(e.target.value);
                    }}
                  />
                </div>
                <input
                  type="email"
                  required
                  name="email"
                  placeholder="Email"
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                />
                <textarea
                  cols="50"
                  required
                  rows="6"
                  placeholder="Your feedback goes here..."
                  value={message}
                  onChange={(e) => {
                    setMessage(e.target.value);
                  }}
                ></textarea>
                <button onClick={handleSubmit} disabled={loader}>
                  {loader ? (
                    <div className="spinner-border"></div>
                  ) : (
                    "SUBMIT FEEDBACK"
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Feedback;
