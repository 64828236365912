import React from "react";
import Compreg from "../../Company/NicePng/niccomp";
export default function CompanyBanner() {

  return (
    <>
      <Compreg />
    
    </>
  );
}
