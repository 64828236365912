import React from "react";
import logo from "./media/indexwhite.png";
import { SiFacebook, SiYoutube } from "react-icons/si";
import { GrInstagram } from "react-icons/gr";
import { BsTwitter, BsLinkedin } from "react-icons/bs";
import "./footer.css";
import appSticker from "./media/download app.png";
import android from "./media/android.png";
import ios from "./media/ios.png";
import { Link } from "react-router-dom";
export default function Footer() {
  return (
    <>
    <div className="max-width-wrap">
    <div className="max-width">

      <footer className="footer-section">
        <div className="container">
          <div className="footer-content pt-5 pb-5">
            <div className="row">
              <div className="col-xl-4 col-lg-4 mb-50">
                <div className="footer-widget">
                  <div className="footer-logo">
                    <a href="index.html">
                      <img src={logo} className="img-fluid" alt="logo" />
                    </a>
                  </div>
                  <div className="footer-text">
                    <p>
                      Thank you for visiting Breakaway as your trusted Delivery Service
                      provider. For any inquiries or to track your shipment,
                      please feel free to reach out to us.
                    </p>
                  </div>
                  <div className="footer-social-icon">
                    {/* <span>Follow us</span> */}
                    <a href="#" className="ftr-icons-a">
                      <SiFacebook />
                    </a>
                    <a href="#" className="ftr-icons-a">
                      <GrInstagram />
                    </a>
                    <a href="#" className="ftr-icons-a">
                      <SiYoutube />
                    </a>
                    <a href="#" className="ftr-icons-a">
                      <BsTwitter />
                    </a>
                    <a href="#" className="ftr-icons-a">
                      <BsLinkedin />
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-6 mb-30">
                <div className="footer-widget">
                  <div className="footer-widget-heading">
                    <h3>Visit </h3>
                  </div>
                  <ul>
                    <li>
                      <Link to="/">
                        <a href="">Home</a>
                      </Link>
                    </li>
                    <Link to="/about">
                      <li>
                        <a href="#">About</a>
                      </li>
                    </Link>
                    <Link to="/">
                      <li>
                        <a href="#">Services</a>
                      </li>
                    </Link>
                    <Link to="/companyorder">
                      <li>
                        <a href="#">Company</a>
                      </li>
                    </Link>
                    <Link to="/contact">
                      <li>
                        <a href="#">Help</a>
                      </li>
                    </Link>
                    <Link to="/signup">
                      <li>
                        <a href="#">Register</a>
                      </li>
                    </Link>
                    <Link to="/company">
                      <li>
                        <a href="#">Log In</a>
                      </li>
                    </Link>
                    <Link to="/contact">
                      <li>
                        <a href="#">Feedback</a>
                      </li>
                    </Link>
                  </ul>
                </div>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-6 mb-50">
                <div className="footer-widget">
                  <div className="footer-widget-heading">
                    <h3>Get On App</h3>
                  </div>
                  <div className="footer-text mb-25">
                    <p>Download Breakaway mobile app in your Android Or IOS.</p>
                  </div>
                  <div className="download-sec-footer"></div>
                  <div className="subscribe-form">
                    <img src={android} alt="" className="download-app-image" />
                    <img src={ios} alt="" className="download-app-image" />

                    {/* <form action="#">
                      <input type="text" placeholder="Email Address" />
                      <button><i className="fab fa-telegram-plane" /></button>
                    </form> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="copyright-area">
          <div className="container">
            <div className="row">
              <div className="col-xl-6 col-lg-6 text-center text-lg-left">
                <div className="copyright-text">
                  <p>
                    Copyright © 2023, All Right Reserved
                    <a href="https://breakaway.ae/">Breakaway</a>
                  </p>
                </div>
              </div>
              <div className="col-xl-6 col-lg-6 d-none d-lg-block text-right">
                <div className="footer-menu">
                  <ul>
                    <li>
                      <a href="#">Home</a>
                    </li>
                    <li>
                      <a href="#">Terms</a>
                    </li>
                    <li>
                      <a href="#">Privacy</a>
                    </li>
                    <li>
                      <a href="#">Policy</a>
                    </li>
                    <li>
                      <a href="#">Contact</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
    </div>

    </>
  );
}
